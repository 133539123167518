define("novnc-sockjs/core/decoders/tightpng", ["exports", "novnc-sockjs/core/decoders/tight"], function (_exports, _tight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2019 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   *
   */

  class TightPNGDecoder extends _tight.default {
    _pngRect(x, y, width, height, sock, display, depth) {
      let data = this._readData(sock);
      if (data === null) {
        return false;
      }
      display.imageRect(x, y, width, height, "image/png", data);
      return true;
    }
    _basicRect(ctl, x, y, width, height, sock, display, depth) {
      throw new Error("BasicCompression received in TightPNG rect");
    }
  }
  _exports.default = TightPNGDecoder;
});