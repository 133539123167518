define("ember-ref-bucket/helpers/ref-to", ["exports", "@ember/component/helper", "ember-ref-bucket/utils/ref", "@ember/destroyable", "@ember/application"], function (_exports, _helper, _ref, _destroyable, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class RefToHelper extends _helper.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_watcher", null);
    }
    compute([name], {
      bucket,
      tracked
    }) {
      const bucketRef = bucket || (0, _application.getOwner)(this);
      if (this._name !== name) {
        if (this._watcher) {
          (0, _destroyable.unregisterDestructor)(this, this._watcher);
        }
        this._watcher = (0, _ref.watchFor)(name, bucketRef, () => {
          this.recompute();
        });
        (0, _destroyable.registerDestructor)(this, this._watcher);
        this._name = name;
      }
      if (tracked) {
        return (0, _ref.bucketFor)(bucketRef).getTracked(name);
      } else {
        return (0, _ref.bucketFor)(bucketRef).get(name);
      }
    }
  }
  _exports.default = RefToHelper;
});