define("novnc-sockjs/core/inflator", ["exports", "novnc-sockjs/vendor/pako/lib/zlib/inflate", "novnc-sockjs/vendor/pako/lib/zlib/zstream"], function (_exports, _inflate, _zstream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2020 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   */

  class Inflate {
    constructor() {
      this.strm = new _zstream.default();
      this.chunkSize = 1024 * 10 * 10;
      this.strm.output = new Uint8Array(this.chunkSize);
      this.windowBits = 5;
      (0, _inflate.inflateInit)(this.strm, this.windowBits);
    }
    setInput(data) {
      if (!data) {
        //FIXME: flush remaining data.
        /* eslint-disable camelcase */
        this.strm.input = null;
        this.strm.avail_in = 0;
        this.strm.next_in = 0;
      } else {
        this.strm.input = data;
        this.strm.avail_in = this.strm.input.length;
        this.strm.next_in = 0;
        /* eslint-enable camelcase */
      }
    }

    inflate(expected) {
      // resize our output buffer if it's too small
      // (we could just use multiple chunks, but that would cause an extra
      // allocation each time to flatten the chunks)
      if (expected > this.chunkSize) {
        this.chunkSize = expected;
        this.strm.output = new Uint8Array(this.chunkSize);
      }

      /* eslint-disable camelcase */
      this.strm.next_out = 0;
      this.strm.avail_out = expected;
      /* eslint-enable camelcase */

      let ret = (0, _inflate.inflate)(this.strm, 0); // Flush argument not used.
      if (ret < 0) {
        throw new Error("zlib inflate failed");
      }
      if (this.strm.next_out != expected) {
        throw new Error("Incomplete zlib block");
      }
      return new Uint8Array(this.strm.output.buffer, 0, this.strm.next_out);
    }
    reset() {
      (0, _inflate.inflateReset)(this.strm);
    }
  }
  _exports.default = Inflate;
});