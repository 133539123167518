define("novnc-sockjs/core/encodings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.encodingName = encodingName;
  _exports.encodings = void 0;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2019 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   */

  const encodings = _exports.encodings = {
    encodingRaw: 0,
    encodingCopyRect: 1,
    encodingRRE: 2,
    encodingHextile: 5,
    encodingTight: 7,
    encodingTightPNG: -260,
    pseudoEncodingQualityLevel9: -23,
    pseudoEncodingQualityLevel0: -32,
    pseudoEncodingDesktopSize: -223,
    pseudoEncodingLastRect: -224,
    pseudoEncodingCursor: -239,
    pseudoEncodingQEMUExtendedKeyEvent: -258,
    pseudoEncodingDesktopName: -307,
    pseudoEncodingExtendedDesktopSize: -308,
    pseudoEncodingXvp: -309,
    pseudoEncodingFence: -312,
    pseudoEncodingContinuousUpdates: -313,
    pseudoEncodingCompressLevel9: -247,
    pseudoEncodingCompressLevel0: -256,
    pseudoEncodingVMwareCursor: 0x574d5664,
    pseudoEncodingExtendedClipboard: 0xc0a1e5ce
  };
  function encodingName(num) {
    switch (num) {
      case encodings.encodingRaw:
        return "Raw";
      case encodings.encodingCopyRect:
        return "CopyRect";
      case encodings.encodingRRE:
        return "RRE";
      case encodings.encodingHextile:
        return "Hextile";
      case encodings.encodingTight:
        return "Tight";
      case encodings.encodingTightPNG:
        return "TightPNG";
      default:
        return "[unknown encoding " + num + "]";
    }
  }
});