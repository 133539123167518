define("novnc-sockjs/core/util/logging", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Warn = _exports.Info = _exports.Error = _exports.Debug = void 0;
  _exports.getLogging = getLogging;
  _exports.initLogging = initLogging;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2019 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   */

  /*
   * Logging/debug routines
   */

  let _logLevel = 'warn';
  let Debug = () => {};
  _exports.Debug = Debug;
  let Info = () => {};
  _exports.Info = Info;
  let Warn = () => {};
  _exports.Warn = Warn;
  let Error = () => {};
  _exports.Error = Error;
  function initLogging(level) {
    if (typeof level === 'undefined') {
      level = _logLevel;
    } else {
      _logLevel = level;
    }
    _exports.Debug = Debug = _exports.Info = Info = _exports.Warn = Warn = _exports.Error = Error = () => {};
    if (typeof window.console !== "undefined") {
      /* eslint-disable no-console, no-fallthrough */
      switch (level) {
        case 'debug':
          _exports.Debug = Debug = console.debug.bind(window.console);
        case 'info':
          _exports.Info = Info = console.info.bind(window.console);
        case 'warn':
          _exports.Warn = Warn = console.warn.bind(window.console);
        case 'error':
          _exports.Error = Error = console.error.bind(window.console);
        case 'none':
          break;
        default:
          throw new window.Error("invalid logging type '" + level + "'");
      }
      /* eslint-enable no-console, no-fallthrough */
    }
  }

  function getLogging() {
    return _logLevel;
  }
  // Initialize logging level
  initLogging();
});