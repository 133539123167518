define("novnc-sockjs/vendor/pako/lib/zlib/gzheader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = GZheader;
  function GZheader() {
    /* true if compressed data believed to be text */
    this.text = 0;
    /* modification time */
    this.time = 0;
    /* extra flags (not used when writing a gzip file) */
    this.xflags = 0;
    /* operating system */
    this.os = 0;
    /* pointer to extra field or Z_NULL if none */
    this.extra = null;
    /* extra field length (valid if extra != Z_NULL) */
    this.extra_len = 0; // Actually, we don't need it in JS,
    // but leave for few code modifications

    //
    // Setup limits is not necessary because in js we should not preallocate memory
    // for inflate use constant limit in 65536 bytes
    //

    /* space at extra (only when reading header) */
    // this.extra_max  = 0;
    /* pointer to zero-terminated file name or Z_NULL */
    this.name = '';
    /* space at name (only when reading header) */
    // this.name_max   = 0;
    /* pointer to zero-terminated comment or Z_NULL */
    this.comment = '';
    /* space at comment (only when reading header) */
    // this.comm_max   = 0;
    /* true if there was or will be a header crc */
    this.hcrc = 0;
    /* true when done reading gzip header (not used when writing a gzip file) */
    this.done = false;
  }
});