define("novnc-sockjs/core/util/strings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.decodeUTF8 = decodeUTF8;
  _exports.encodeUTF8 = encodeUTF8;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2019 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   */

  // Decode from UTF-8
  function decodeUTF8(utf8string, allowLatin1 = false) {
    try {
      return decodeURIComponent(escape(utf8string));
    } catch (e) {
      if (e instanceof URIError) {
        if (allowLatin1) {
          // If we allow Latin1 we can ignore any decoding fails
          // and in these cases return the original string
          return utf8string;
        }
      }
      throw e;
    }
  }

  // Encode to UTF-8
  function encodeUTF8(DOMString) {
    return unescape(encodeURIComponent(DOMString));
  }
});