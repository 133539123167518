define("novnc-sockjs/core/util/int", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toSigned32bit = toSigned32bit;
  _exports.toUnsigned32bit = toUnsigned32bit;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2020 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   */

  function toUnsigned32bit(toConvert) {
    return toConvert >>> 0;
  }
  function toSigned32bit(toConvert) {
    return toConvert | 0;
  }
});