define("novnc-sockjs/core/deflator", ["exports", "novnc-sockjs/vendor/pako/lib/zlib/deflate", "novnc-sockjs/vendor/pako/lib/zlib/zstream"], function (_exports, _deflate, _zstream) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * noVNC: HTML5 VNC client
   * Copyright (C) 2020 The noVNC Authors
   * Licensed under MPL 2.0 (see LICENSE.txt)
   *
   * See README.md for usage and integration instructions.
   */

  class Deflator {
    constructor() {
      this.strm = new _zstream.default();
      this.chunkSize = 1024 * 10 * 10;
      this.outputBuffer = new Uint8Array(this.chunkSize);
      this.windowBits = 5;
      (0, _deflate.deflateInit)(this.strm, this.windowBits);
    }
    deflate(inData) {
      /* eslint-disable camelcase */
      this.strm.input = inData;
      this.strm.avail_in = this.strm.input.length;
      this.strm.next_in = 0;
      this.strm.output = this.outputBuffer;
      this.strm.avail_out = this.chunkSize;
      this.strm.next_out = 0;
      /* eslint-enable camelcase */

      let lastRet = (0, _deflate.deflate)(this.strm, _deflate.Z_FULL_FLUSH);
      let outData = new Uint8Array(this.strm.output.buffer, 0, this.strm.next_out);
      if (lastRet < 0) {
        throw new Error("zlib deflate failed");
      }
      if (this.strm.avail_in > 0) {
        // Read chunks until done

        let chunks = [outData];
        let totalLen = outData.length;
        do {
          /* eslint-disable camelcase */
          this.strm.output = new Uint8Array(this.chunkSize);
          this.strm.next_out = 0;
          this.strm.avail_out = this.chunkSize;
          /* eslint-enable camelcase */

          lastRet = (0, _deflate.deflate)(this.strm, _deflate.Z_FULL_FLUSH);
          if (lastRet < 0) {
            throw new Error("zlib deflate failed");
          }
          let chunk = new Uint8Array(this.strm.output.buffer, 0, this.strm.next_out);
          totalLen += chunk.length;
          chunks.push(chunk);
        } while (this.strm.avail_in > 0);

        // Combine chunks into a single data

        let newData = new Uint8Array(totalLen);
        let offset = 0;
        for (let i = 0; i < chunks.length; i++) {
          newData.set(chunks[i], offset);
          offset += chunks[i].length;
        }
        outData = newData;
      }

      /* eslint-disable camelcase */
      this.strm.input = null;
      this.strm.avail_in = 0;
      this.strm.next_in = 0;
      /* eslint-enable camelcase */

      return outData;
    }
  }
  _exports.default = Deflator;
});